<template>
  <div>
    <b-form
      class="camp-container pb-6"
      ref="frmCadastrarAgenda"
      @submit.prevent="salvar"
      method="POST"
    >
      <Toast position="top-right" />
      <ModalConfirmacaoSair direct_page="agende.agenda" />

      <div class="camp-header">
        <div class="back-arrow-squared" @click="showModalConfirmacaoSair">
          <i class="flaticon-up-arrow" style="color: #808080 !important"></i>
        </div>
      </div>

      <div class="container-fluid">
        <div class="d-flex" style="gap: 1rem">
          <div class="w-100">
            <b-form-group>
              <label class="text-title">Nome</label>
              <b-form-input
                name="nome"
                v-model="formAgenda.nome"
              ></b-form-input>
            </b-form-group>
          </div>
          <modal
            name="modal-color-picker"
            scrollable
            :width="400"
            height="auto"
            :style="{
              padding: '1rem'
            }"
          >
            <div
              :style="{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1rem'
              }"
            >
              <v-color-picker
                show-swatches
                hide-inputs
                hide-sliders
                v-model="formAgenda.cor"
              ></v-color-picker>
            </div>
          </modal>
          <div class="">
            <b-form-group>
              <label class="text-title">Cor</label>
              <div class="d-block">
                <b-button
                  variant="primary"
                  class="btn btn-primary"
                  @click="$modal.show('modal-color-picker')"
                  :style="{
                    backgroundColor: formAgenda.cor,
                    borderColor: formAgenda.cor,
                    color: formAgenda.cor
                  }"
                >
                  #####
                </b-button>
              </div>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <b-form-group>
              <label class="text-title">Licenciada</label>
              <b-form-select
                name="licenciada"
                :options="opcoesLicenciada"
                v-model="defaultOpcoes.licenciada"
                @change="obterOpcoesModeloTipo"
              ></b-form-select>
            </b-form-group>
          </div>

          <div class="col-md-4">
            <b-form-group>
              <label class="text-title">Tipo</label>
              <b-form-select
                name="tipo"
                :options="opcoesTipo"
                v-model="defaultOpcoes.tipo"
                @change="filtrarModelosPorTipo"
              ></b-form-select
            ></b-form-group>
          </div>

          <div class="col-md-4">
            <b-form-group>
              <label class="text-title">Modelo</label>
              <b-form-select
                name="modelo"
                :options="opcoesModeloFiltrado"
                v-model="formAgenda.modelo"
                @change="selecionarModelo"
              ></b-form-select
            ></b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <b-form-group>
              <label class="text-title">Selecione os dias da semana:</label>
              <pv-multiselect
                class="form-control"
                optionLabel="text"
                emptyFilterMessage="Nenhum resultado"
                :filter="true"
                :options="opcoesDiasSemana"
                placeholder="Todos"
                v-model="defaultOpcoes.diasSemana"
                @input="obterDiasSemana"
              >
              </pv-multiselect>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group>
              <label class="text-title">Tipo de Moderação:</label>
              <b-form-select
                name="tipoDeModeracao"
                :options="tiposDeModeracao"
                v-model="formAgenda.tipoModeracao"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group>
              <label class="text-title">Tipo de Agendamento:</label>
              <b-form-select
                name="tipoDeModeracao"
                :options="tipoAgendamentoOpcoes"
                v-model="formAgenda.tipoAgendamento"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <b-form-group>
              <label class="text-title">
                Tratamento de Erro em Sincronias:
              </label>
              <i
                v-b-tooltip.hover
                title="Fluxo para tratamento de erro em sincronia de Agendamento. Não é ativado em Cancelamento de Agendamento"
                class="fa fa-question-circle ml-3"
                style="color: #ff8c00; cursor: pointer"
              ></i>
              <b-form-select
                name="fluxo"
                :options="opcoesFluxo"
                v-model="formAgenda.chatbot_fluxo_id"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>

        <b-form-group>
          <div class="checkbox-inline">
            <label class="checkbox text-title">
              <input
                type="checkbox"
                name="herdarDoSistema"
                v-model="formAgenda.herdarDoSistema"
                @change="verificarModeloTipo"
                ref="herdarDoSistemaCheckbox"
              />
              <span></span>
              Utilizar grade do&nbsp;
              <b style="font-weight: 600">{{ nomeDoSistemaSelecionado }}</b>
            </label>
          </div>
        </b-form-group>

        <b-form-group v-if="!formAgenda.herdarDoSistema">
          <div class="checkbox-inline">
            <label class="checkbox text-title">
              <input
                type="checkbox"
                name="existeIntervalo"
                v-model="formAgenda.existeIntervalo"
              />
              <span></span>
              Definir horário de intervalo
            </label>
          </div>
        </b-form-group>

        <div class="row" v-if="!formAgenda.herdarDoSistema">
          <div class="col-md-3">
            <b-form-group>
              <label class="text-title">Horário inicial</label>
              <b-form-input
                type="time"
                name="horario_inicial"
                v-model="formAgenda.horarioInicial"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group>
              <label class="text-title">Horário final</label>
              <b-form-input
                type="time"
                name="horario_final"
                v-model="formAgenda.horarioFinal"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3" v-show="formAgenda.existeIntervalo">
            <b-form-group>
              <label class="text-title">Intervalo Horário Inicial</label>
              <b-form-input
                type="time"
                name="intervalo_horario_inicial"
                v-model="formAgenda.intervaloHorarioInicial"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3" v-show="formAgenda.existeIntervalo">
            <b-form-group>
              <label class="text-title">Intervalo Horário Final</label>
              <b-form-input
                type="time"
                name="intervalo_horario_final"
                v-model="formAgenda.intervaloHorarioFinal"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <b-form-group>
              <label class="text-title">Qtd. de atendimentos</label>
              <b-form-input
                name="quantidade_atendimentos"
                v-model="formAgenda.quantidadeAtendimentos"
                data-inputmask="'alias': 'integer'"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group>
              <label class="text-title">Tipo de atendimento</label>
              <b-form-select
                name="tipoAtendimento"
                :options="tipoAtendimentoOpcoes"
                v-model="formAgenda.tipoAtendimento"
              ></b-form-select>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group>
              <label class="text-title">Tempo de atendimento</label>
              <b-form-input
                type="text"
                name="tempo_atendimento"
                v-model="formAgenda.tempoAtendimento"
                data-inputmask="'alias': 'integer'"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3" v-if="!formAgenda.herdarDoSistema">
            <b-form-group>
              <label class="text-title">Intervalo entre atendimentos</label>
              <b-form-input
                type="text"
                name="intervalo_atendimento"
                v-model="formAgenda.intervaloAtendimento"
                data-inputmask="'alias': 'integer'"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row" v-if="!formAgenda.herdarDoSistema">
          <div class="col-md-3">
            <b-form-group>
              <label class="text-title">Qtd. de dias de antecedência</label>
              <b-form-input
                type="text"
                name="qtd_dias_antecedencia"
                v-model="formAgenda.qtdDiasAntecedencia"
                data-inputmask="'alias': 'integer'"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group>
              <label class="text-title">Qtd. de dias máximo</label>
              <b-form-input
                type="text"
                name="qtd_dias_maximo"
                v-model="formAgenda.qtdDiasMaximo"
                data-inputmask="'alias': 'integer'"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <p class="text-title">
              Opções Avançadas
              <b-button
                style="width: 40px"
                @click="exibirOpcoesAvancadas = !exibirOpcoesAvancadas"
                v-b-toggle="'avancadoCollapse'"
              >
                <i
                  v-if="exibirOpcoesAvancadas"
                  class="ki ki-arrow-down icon-nm"
                  style="color: inherit"
                ></i>
                <i
                  v-if="!exibirOpcoesAvancadas"
                  class="ki ki-arrow-up icon-nm"
                  style="color: inherit"
                ></i>
              </b-button>
            </p>
          </div>
        </div>

        <b-collapse id="avancadoCollapse" class="collapse-avancadas mb-4">
          <div class="row">
            <div class="col-md-3">
              <b-form-group class="mb-0">
                <label>Data de início</label>
                <b-form-input
                  type="date"
                  name="data_inicio"
                  v-model="formAgenda.dataInicio"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group class="mb-0">
                <label>Data de fim</label>
                <b-form-input
                  type="date"
                  name="data_fim"
                  v-model="formAgenda.dataFim"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </b-collapse>

        <div class="row">
          <div class="col-md-12 text-right">
            <b-button
              variant="warning"
              @click="showModalFiltros"
              class="text-uppercase mr-4"
              >Mostrar Filtros
            </b-button>
            <b-button variant="success" type="submit" class="text-uppercase"
              >Salvar Agenda</b-button
            >
          </div>
        </div>
      </div>
    </b-form>

    <modal
      :clickToClose="false"
      name="modal-filtros"
      scrollable
      width="90%"
      height="auto"
      :styles="{ overflow: 'visible !important' }"
    >
      <div class="modal-header col">
        <div class="buttons-container d-flex justify-content-end w-100">
          <b-button
            @click="returnLastFiltersState"
            variant="danger"
            class="mr-3 text-uppercase"
            >Cancelar</b-button
          >

          <b-button
            @click="hideModalFiltros"
            variant="success"
            class="text-uppercase"
            >Aplicar</b-button
          >
        </div>
      </div>
      <b-tabs>
        <b-tab title="Filtro Agenda" class="p-4">
          <div
            class="col d-flex flex-column justify-content-center align-items-center"
          >
            <div class="nested-header w-100">
              <div class="d-flex"></div>
              <div class="d-flex">
                <button
                  class="btn botao-filtro-add text-uppercase"
                  @click="adicionarNovoFiltro()"
                >
                  + Adicionar novo filtro
                </button>
              </div>
            </div>

            <nested-draggable
              :group="0"
              :listaDeFiltros="listaDeFiltros"
              :camposPossiveisDoFiltro="camposPossiveisDoFiltro"
              :opreadoresFiltro="opreadoresFiltro"
              class="col mt-5"
              :isDraggable="true"
              :sistemaLicenciadaId="formAgenda.licenciada"
            >
            </nested-draggable>
          </div>
        </b-tab>
        <b-tab title="Configuração Padrão" class="p-4">
          <VueJSONEditor
            ref="jsoneditor"
            :content="content"
            :onChange="onChange"
          />
        </b-tab>
      </b-tabs>
    </modal>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import ModalConfirmacaoSair from "../../automacao/campanha/cadastro-componentes/ModalConfirmacaoSair";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import API_LINKS from "../../automacao/api.links";

import nestedDraggable from "../../automacao/campanha/cadastro-componentes/NestedDraggable.vue";

import Inputmask from "inputmask";
import VueJSONEditor from "../../automacao/licenciada/components/VueJSONEditor";
import { SET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";
import config from "@/core/config/layout.config.json";
import { mapGetters } from "vuex";

export default {
  name: "CadastroAgenda",
  components: {
    nestedDraggable,
    Toast,
    ModalConfirmacaoSair,
    VueJSONEditor
  },

  data() {
    return {
      defaultOpcoes: {
        licenciada: null,
        tipo: null,
        fluxo: null,
        diasSemana: null
      },
      opcoesLicenciada: [
        {
          value: null,
          text: "Selecione"
        }
      ],
      opcoesFluxo: [
        {
          value: null,
          text: "Sem Tratamento selecionado"
        }
      ],
      opcoesTipo: [
        {
          value: null,
          text: "Selecione"
        }
      ],
      opcoesModelo: [
        {
          value: null,
          text: "Selecione"
        }
      ],
      opcoesModeloFiltrado: [
        {
          value: null,
          text: "Selecione"
        }
      ],
      opcoesDiasSemana: [
        {
          value: "SEG",
          text: "Segunda-feira"
        },
        {
          value: "TER",
          text: "Terça-feira"
        },
        {
          value: "QUA",
          text: "Quarta-feira"
        },
        {
          value: "QUI",
          text: "Quinta-feira"
        },
        {
          value: "SEX",
          text: "Sexta-feira"
        },
        {
          value: "SAB",
          text: "Sábado"
        },
        {
          value: "DOM",
          text: "Domingo"
        }
      ],
      formAgenda: {
        id: null,
        nome: "",
        licenciada: null,
        tipo: null,
        modelo: null,
        diasSemana: [],
        horarioInicial: "",
        horarioFinal: "",
        existeIntervalo: false,
        intervaloHorarioInicial: null,
        intervaloHorarioFinal: null,
        quantidadeAtendimentos: "",
        tipoAtendimento: null,
        tempoAtendimento: "",
        intervaloAtendimento: "",
        qtdDiasAntecedencia: "",
        qtdDiasMaximo: "",
        dataInicio: "",
        dataFim: "",
        herdarDoSistema: false,
        tipoModeracao: null,
        filtros: [],
        parametrosJson: null,
        cor: "#FF7043FF",
        tipoAgendamento: null,
        chatbot_fluxo_id: null
      },
      tipoAgendamentoOpcoes: [
        {
          text: "Selecione",
          value: null
        },
        {
          text: "Priorizar Filtros",
          value: "PF"
        },
        {
          text: "Priorizar Disponibilidade",
          value: "PD"
        }
      ],
      tiposDeModeracao: [
        {
          text: "Selecione",
          value: null
        },
        {
          text: "Sem Moderação",
          value: "SM"
        },
        {
          text: "Com Moderação",
          value: "CM"
        },
        {
          text: "Com Moderação Sem Identificação",
          value: "SI"
        }
      ],
      tipoAtendimentoOpcoes: [
        {
          text: "Selecione",
          value: null
        },
        {
          text: "Horário marcado",
          value: "HM"
        },
        {
          text: "Ordem de chegada",
          value: "OC"
        }
      ],
      exibirOpcoesAvancadas: false,

      listaDeFiltros: [],
      camposPossiveisDoFiltro: [],
      previouslyStateOfFilters: [],
      opreadoresFiltro: [],
      content: {
        json: {},
        text: undefined
      },
      ultimosParametrosJson: ""
    };
  },

  computed: {
    ...mapGetters(["validatorErrors"]),
    nomeDoSistemaSelecionado() {
      if (!this.formAgenda.licenciada) {
        return "Sistema";
      } else {
        return this.opcoesLicenciada.find(
          (licenciada) =>
            licenciada?.value?.licenciada === this.formAgenda.licenciada
        ).text;
      }
    }
  },

  watch: {
    "formAgenda.existeIntervalo": function (value) {
      if (!value) {
        this.formAgenda.intervaloHorarioInicial = null;
        this.formAgenda.intervaloHorarioFinal = null;
      }
    },
    // formAgenda.herdarDoSistema
    "defaultOpcoes.tipo": {
      handler: function (val) {
        if (this.formAgenda.herdarDoSistema) {
          this.verificarModeloTipo();
        }
      },
      immediate: false
    },
    listaDeFiltros: {
      handler(val) {
        // Esse método tá bugado pakas.
        function removeLastMfiEOu(lista) {
          // Verifica se a lista tem algum elemento
          if (lista.length > 0) {
            // Verifica se a lista do último elemento da lista de hierarquia 0 tem algum elemento
            if (lista[lista.length - 1].listaDeFiltros.length > 0) {
              // Verifica se o último elemento da lsita de hierarquia 1 tem algum elemento
              if (
                lista[lista.length - 1].listaDeFiltros[
                  lista[lista.length - 1].listaDeFiltros.length - 1
                ].listaDeFiltros.length > 0
              ) {
                const listThree =
                  lista[lista.length - 1].listaDeFiltros[
                    lista[lista.length - 1].listaDeFiltros.length - 1
                  ].listaDeFiltros;
                if (listThree[listThree.length - 1].listaDeFiltros.length > 0) {
                  delete listThree[listThree.length - 1].listaDeFiltros[
                    listThree[listThree.length - 1].listaDeFiltros.length - 1
                  ].mfi_e_ou;
                } else {
                  delete lista[lista.length - 1].listaDeFiltros[
                    lista[lista.length - 1].listaDeFiltros.length - 1
                  ].listaDeFiltros[
                    lista[lista.length - 1].listaDeFiltros[
                      lista[lista.length - 1].listaDeFiltros.length - 1
                    ].listaDeFiltros.length - 1
                  ].mfi_e_ou;
                }
              } else {
                delete lista[lista.length - 1].listaDeFiltros[
                  lista[lista.length - 1].listaDeFiltros.length - 1
                ].mfi_e_ou;
              }
            } else {
              delete lista[lista.length - 1].mfi_e_ou;
            }
          }
        }

        function addMfiToAllFilters(lista) {
          if (lista.length > 0) {
            lista.forEach((filtro0) => {
              if (
                !filtro0.hasOwnProperty("mfi_e_ou") &&
                filtro0.listaDeFiltros.length === 0
              ) {
                filtro0.mfi_e_ou = "AND";
              }
              if (filtro0.listaDeFiltros.length > 0) {
                filtro0.listaDeFiltros.forEach((filtro1) => {
                  if (
                    !filtro1.hasOwnProperty("mfi_e_ou") &&
                    filtro1.listaDeFiltros.length === 0
                  ) {
                    filtro1.mfi_e_ou = "AND";
                  }
                  if (filtro1.listaDeFiltros.length > 0) {
                    filtro1.listaDeFiltros.forEach((filtro2) => {
                      if (
                        !filtro2.hasOwnProperty("mfi_e_ou") &&
                        filtro2.listaDeFiltros.length === 0
                      ) {
                        filtro2.mfi_e_ou = "AND";
                      }
                      if (filtro2.listaDeFiltros.length > 0) {
                        filtro2.listaDeFiltros.forEach((filtro3) => {
                          if (!filtro3.hasOwnProperty("mfi_e_ou")) {
                            filtro3.mfi_e_ou = "AND";
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        }

        addMfiToAllFilters(val);
        removeLastMfiEOu(val);

        this.$forceUpdate();
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "aside-minimize");
  },
  mounted() {
    let newConfig = JSON.parse(JSON.stringify(config));
    this.$store.dispatch(ADD_BODY_CLASSNAME, "aside-minimize");
    newConfig.styleContetnt = { padding: "0" };
    newConfig.styleContainer = { "max-width": "100%", margin: 0 };
    newConfig.aside.self.minimize.hoverable = true;
    this.$store.dispatch(SET_LAYOUT_CONFIG, newConfig);
    const init = async () => {
      await ApiService.get(API_LINKS.campanha + "/operador").then((res) => {
        this.opreadoresFiltro = res.data.operadores.map((v) => {
          return {
            ope_titulo: v.ope_titulo,
            ope_sinal: v.ope_sinal,
            operador_id: v.operador_id
          };
        });
      });

      await ApiService.get("agenda/opcoes-licenciada").then(
        async (response) => {
          this.opcoesLicenciada = response.data.data;
          const agenda = this.$route.params.agendaObj;
          if (agenda) {
            this.selecionarModelo(agenda.modelo);
            const licenciada = this.opcoesLicenciada.find((obj) => {
              if (!obj.value) {
                return false;
              }

              return agenda.licenciada === obj.value.licenciada;
            });

            this.defaultOpcoes.licenciada = licenciada.value;

            await this.obterOpcoesModeloTipo(
              licenciada.value,
              agenda.tipo,
              agenda.modelo
            );

            this.formAgenda.id = agenda.id;
            this.formAgenda.nome = agenda.nome;
            this.formAgenda.licenciada = agenda.licenciada;
            this.formAgenda.tipo = agenda.tipo;
            this.formAgenda.modelo = agenda.modelo;
            this.formAgenda.diasSemana = agenda.dias_semana;
            this.formAgenda.horarioInicial = agenda.horario_inicial;
            this.formAgenda.horarioFinal = agenda.horario_final;
            this.formAgenda.intervaloHorarioInicial =
              agenda.intervalo_horario_inicial;
            this.formAgenda.intervaloHorarioFinal =
              agenda.intervalo_horario_final;
            this.formAgenda.quantidadeAtendimentos =
              agenda.quantidade_atendimentos;
            this.formAgenda.tipoAtendimento = agenda.tipo_atendimento;
            this.formAgenda.tempoAtendimento = agenda.tempo_atendimento;
            this.formAgenda.intervaloAtendimento = agenda.intervalo_atendimento;
            this.formAgenda.qtdDiasAntecedencia = agenda.qtd_dias_antecedencia;
            this.formAgenda.qtdDiasMaximo = agenda.qtd_dias_maximo;
            this.formAgenda.dataInicio = agenda.data_inicio;
            this.formAgenda.dataFim = agenda.data_fim;
            this.formAgenda.herdarDoSistema = agenda.mar_herdar_do_sistema;
            this.formAgenda.tipoModeracao = agenda.mar_tipo_moderacao;
            this.formAgenda.tipoAgendamento = agenda.mar_tipo_agendamento;
            // Shallow copy of agenda.filtros
            this.formAgenda.filtros = agenda.filtros;

            this.formAgenda.parametrosJson = agenda.parametrosJson;
            this.content = {
              json: JSON.parse(agenda.parametrosJson),
              text: undefined
            };

            if (agenda.intervalo_horario_final) {
              this.formAgenda.existeIntervalo = true;
            }
            this.formAgenda.cor = agenda.mar_cor ? agenda.mar_cor : "#FF7043FF";

            this.listaDeFiltros = agenda.filtros;
            this.$forceUpdate();
          }

          this.validacao();
          this.carregando = false;
        }
      );

      await ApiService.get("administrativo/chatbot/fluxo").then(
        async (response) => {
          this.opcoesFluxo = response.data.fluxos.map((f) => {
            return { value: f.chatbot_fluxo_id, text: f.cfl_titulo };
          });

          this.opcoesFluxo.unshift({
            value: null,
            text: "Sem Tratamento"
          });

          const agenda = this.$route.params.agendaObj;
          if (agenda) {
            const fluxo = this.opcoesFluxo.find((obj) => {
              return agenda.chatbot_fluxo_id === obj.value;
            });

            if (fluxo) {
              this.defaultOpcoes.chatbot_fluxo_id = fluxo.value;
              this.formAgenda.chatbot_fluxo_id = fluxo.value;
            }
          }

          this.validacao();
        }
      );
    };

    init();
  },

  methods: {
    verificarModeloTipo() {
      // herdarDoSistemaCheckbox get this ref
      const refedCheckbox = this.$refs.herdarDoSistemaCheckbox;
      if (this.defaultOpcoes.tipo != "CONSULTA_GRADE") {
        // Create an error swal
        Swal.fire({
          title: "MENSAGEM",
          text: "Não é possível herdar regras do sistema com esse tipo de modelo!",
          icon: "error",
          heightAuto: false
        });
        this.formAgenda.herdarDoSistema = false;
        refedCheckbox.checked = false;
      }
    },
    showModalConfirmacaoSair() {
      this.$modal.show("modal-confirmacao-sair");
    },

    salvar(e) {
      e.preventDefault();

      this.fv.validate().then((status) => {
        if (status == "Valid") {
          this.formAgenda.filtros = JSON.parse(
            JSON.stringify(this.listaDeFiltros)
          );

          ApiService.post("agenda/salvar", this.formAgenda)
            .then((response) => {
              Swal.fire({
                text: "Salvo com sucesso!",
                icon: "success",
                heightAuto: false
              }).then((r) => {
                if (r.isConfirmed) {
                  this.$router.push({
                    name: "agende.agenda",
                    params: {
                      navSelecionada: 1
                    }
                  });
                }
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    },

    async obterOpcoesModeloTipo(
      obj,
      tipoSelecionado = null,
      modeloSelecionado = null
    ) {
      this.formAgenda.licenciada = obj ? obj.licenciada : null;
      const banco = obj ? obj.banco : null;

      if (banco) {
        await ApiService.post("agenda/opcoes-modelo", { banco: banco }).then(
          (response) => {
            this.opcoesModelo = response.data.data.opcoesModelo;
            this.opcoesTipo = response.data.data.opcoesTipo;

            if (tipoSelecionado) {
              const tipo = this.opcoesTipo.find((obj) => {
                if (!obj.value) {
                  return false;
                }

                return tipoSelecionado === obj.value;
              });

              this.defaultOpcoes.tipo = tipo.value;
              this.filtrarModelosPorTipo(tipo.value);
            }

            if (modeloSelecionado) {
              const modelo = this.opcoesModelo.find((obj) => {
                if (!obj.value) {
                  return false;
                }

                return modeloSelecionado === obj.value;
              });

              this.defaultOpcoes.modelo = modelo.value;
            }
          }
        );
      } else {
        this.opcoesTipo = [
          {
            value: null,
            text: "Selecione"
          }
        ];
        this.defaultOpcoes.tipo = null;

        this.opcoesModelo = [
          {
            value: null,
            text: "Selecione"
          }
        ];

        this.opcoesModeloFiltrado = [
          {
            value: null,
            text: "Selecione"
          }
        ];

        this.formAgenda.modelo = null;

        this.fv.revalidateField("licenciada");
        this.fv.revalidateField("tipo");
        this.fv.revalidateField("modelo");
      }
    },

    filtrarModelosPorTipo(tipo) {
      this.opcoesModeloFiltrado = this.opcoesModelo.filter(function (modelo) {
        return modelo.tipo === tipo || modelo.value === null;
      });
    },

    validacao() {
      const frmCadastrarAgenda = this.$refs["frmCadastrarAgenda"];

      Inputmask().mask(frmCadastrarAgenda.querySelectorAll("input"));

      this.fv = formValidation(frmCadastrarAgenda, {
        fields: {
          nome: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          licenciada: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          tipo: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          modelo: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          tipoDeModeracao: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          }
          // tipoAtendimento: {
          //   validators: {
          //     notEmpty: {
          //       message: "Campo obrigatório."
          //     },
          //     blank: {}
          //   }
          // }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    returnLastFiltersState() {
      this.listaDeFiltros = this.previouslyStateOfFilters;
      this.content.json = JSON.parse(this.ultimosParametrosJson);
      this.formAgenda.parametrosJson = this.ultimosParametrosJson;

      this.$modal.hide("modal-filtros");
    },

    hideModalFiltros() {
      this.$modal.hide("modal-filtros");
    },
    async getAgendaFiltros(modelo_selecionado) {
      const filtros = ApiService.get(
        API_LINKS.campanhaFiltro + "/" + modelo_selecionado
      );
      return filtros;
    },
    selecionarModelo(modelo_selecionado) {
      this.getAgendaFiltros(modelo_selecionado).then((res) => {
        this.content = {
          json: res.data.objPadrao,
          text: undefined
        };
        this.formAgenda.parametrosJson = JSON.stringify(res.data.objPadrao);

        this.camposPossiveisDoFiltro = res.data.filtros.map((filtro) => {
          return {
            fil_campo: filtro.fil_campo,
            fil_titulo: filtro.fil_titulo,
            filtro_id: filtro.filtro_id,
            ope_titulo: filtro.ope_titulo,
            fil_tipo_dado: filtro.fil_tipo_dado,
            fil_tipo: filtro.fil_tipo,
            fil_mascara: filtro.fil_mascara
          };
        });
      });
    },
    showModalFiltros() {
      if (this.formAgenda.modelo) {
        const str = JSON.stringify(this.listaDeFiltros);
        this.previouslyStateOfFilters = JSON.parse(str);
        this.ultimosParametrosJson = this.formAgenda.parametrosJson;
        this.$modal.show("modal-filtros");
      } else {
        Swal.fire({
          title: "MENSAGEM",
          text: "Selecione os campos Licenciada, Tipo e Modelo para habilitar os filtros.",
          icon: "warning",
          heightAuto: false
        });
      }
    },

    adicionarNovoFiltro() {
      this.listaDeFiltros.push({
        hierarquia: 0,
        filtro_id: 1,
        mfi_valor: "",
        ope_titulo: "",
        mfi_e_ou: "AND",
        mfi_valor_2: null, //(apenas se for um between),
        operador_id: 12, //(cada condição vai ter um id),
        listaDeFiltros: [],
        contexto_id: null
      });
      this.listaDeFiltros.forEach((v, i) => {
        if (!(Array.isArray(v.listaDeFiltros) && v.listaDeFiltros.length)) {
          if (!v.mfi_e_ou) v.mfi_e_ou = "AND";
        }
        delete this.listaDeFiltros[this.listaDeFiltros.length - 1].mfi_e_ou;
      });
    },

    obterDiasSemana(arr) {
      this.formAgenda.diasSemana = arr.map((o) => o.value);
    },

    onChange(content) {
      this.content = content;
      this.formAgenda.parametrosJson = JSON.stringify(content.json);
    }
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&display=swap");

::v-deep .p-colorpicker-preview {
  width: 2.95rem !important;
  height: 2.95rem !important;
}

.camp-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e6ef;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.camp-container {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}

.camp-body {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  flex-wrap: wrap;
}

.collapse-avancadas {
  background: #e4e6ef;
  padding: 12px;
  border-radius: 8px;
  background: #e4e6ef !important;
}

.back-arrow-squared {
  border: 1px solid #808080;
  border-radius: 4px;
  transform: rotate(-90deg);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: unset !important;
}

.modal-filtros {
  padding: 32px 32px 16px 32px;
  position: relative;
}

.nested-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nested-header .botao {
  text-transform: uppercase;
}

.botao-filtro-add {
  border: 1px solid #1bc5bd;
  color: #1bc5bd;
  padding: 2px 6px;
  border-radius: 5px;
  z-index: 10;
  padding: 4px 12px;
  height: 38px;
}

.text-title {
  font-family: "Inter", Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(12px + 0.3vw);
  color: #27282c;
  font-weight: 300;
}
</style>
